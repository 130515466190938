import React from "react";

const About = () => {
	return (
		<div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300">
			<div className="flex flex-col justify-center items-center w-full h-full">
				<div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
					<div className="sm:text-right pb-8 pl-4">
						<p className="text-4xl font-bold inline border-b-4 border-pink-600">
							About
						</p>
					</div>
				</div>
				<div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
					<div className="sm:text-right text-4xl font-bold">
						<p>
							Hi.I'm Recep, my main target is to make easy and simple
							applications for users.
						</p>
					</div>
					<div>
						<p>
							I am a Front-End Developer with experienced in building scalable
							and responsive web applications. Extensive knowledge of JavaScript
							and professional experience working with React JS .I am always
							passionate about building excellent applications that improves the
							lives of people.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;