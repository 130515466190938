import React, { useState,useEffect } from "react";
import { FaBars, FaTimes, FaGithub, FaLinkedin, FaPhone } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../assets/react.png";
import { Link } from "react-scroll";
import resume from "../assets/resume.pdf";

const Header = () => {
	const [nav, setNav] = useState(false);
	const handleClick = () => setNav(!nav);
	const [bg, setBg] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 80) {
        setBg(true);
      } else {
        setBg(false);
      }
    });
  });

	return (
		<header className= {`${
			bg && 'bg-white shadow-md py-4'
		  } p-8 lg:px-0 w-full fixed z-10 transition-all duration-300  fixed w-full h-[60px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300`}>
			<div className ="container mx-auto flex items-center justify-between  sm:text-7x1  font-bold text-[#ccd6f6]">
			<Link onClick={handleClick} to="home" smooth={true} duration={500}>
			<img src={Logo} alt="Logo " style={{ width: "50px",cursor:"pointer"}} />
					</Link>
			
		

			{/* menu */}
			<ul className="hidden md:flex">
				<li>
					<Link to="home" smooth={true} duration={500}>
						HOME
					</Link>
				</li>
				<li>
					<Link to="about" smooth={true} duration={500}>
						ABOUT
					</Link>
				</li>
				<li>
					<Link to="skills" smooth={true} duration={500}>
						SKILLS
					</Link>
				</li>
				<li>
					<Link to="projects" smooth={true} duration={500}>
						PROJECTS
					</Link>
				</li>
				<li>
					<Link to="contact" smooth={true} duration={500}>
						CONTACT
					</Link>
				</li>
			</ul>

			{/* Hamburger */}
			<div onClick={handleClick} className="md:hidden z-10">
				{!nav ? <FaBars /> : <FaTimes />}
			</div>
			</div>

			{/* Mobile menu */}
			<ul
				className={
					!nav
						? "hidden"
						: "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"
				}
			>
				<li className="py-6 text-4xl">
					<Link onClick={handleClick} to="home" smooth={true} duration={500}>
						HOME
					</Link>
				</li>
				<li className="py-6 text-4xl">
					<Link onClick={handleClick} to="about" smooth={true} duration={500}>
						ABOUT
					</Link>
				</li>
				<li className="py-6 text-4xl">
					<Link onClick={handleClick} to="skills" smooth={true} duration={500}>
						SKILLS
					</Link>
				</li>
				<li className="py-6 text-4xl">
					<Link
						onClick={handleClick}
						to="projects"
						smooth={true}
						duration={500}
					>
						PROJECTS
					</Link>
				</li>
				<li className="py-6 text-4xl">
					<Link onClick={handleClick} to="contact" smooth={true} duration={500}>
						CONTACT
					</Link>
				</li>
			</ul>

			{/* Social icons */}
			<div className="hidden lg:flex fixed flex-col top-[35%] left-0">
				<ul>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
						<a
							className="flex justify-between items-center w-full text-gray-300"
							href="https://www.linkedin.com/in/recep-taylanhan-abc/"
						>
							Linkedin <FaLinkedin size={30} />
						</a>
					</li>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
						<a
							className="flex justify-between items-center w-full text-gray-300"
							href="https://github.com/Recep-T"
						>
							Github <FaGithub size={30} />
						</a>
					</li>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
						<a
							className="flex justify-between items-center w-full text-gray-300"
							href="mailto:taylanhanr@gmail.com"
						>
							Email
							<HiOutlineMail size={30} />
						</a>
					</li>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]">
						<a
							className="flex justify-between items-center w-full text-gray-300"
							href="../assets/resume.pdf" download={resume}
						>
							Resume <BsFillPersonLinesFill size={30} />
						</a>
					</li>
					<li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]">
						<a href="/"
							className="flex justify-between items-center w-full text-gray-300"
						>
							+1-222-333-4444 <FaPhone size={30} />
						</a>
					</li>
				</ul>
			</div>
		</header>
	);
};

export default Header;

// Project Console: https://console.firebase.google.com/project/my-website-34127/overview
// Hosting URL: https://my-website-34127.web.app