import React from "react";
import Image from '../assets/lawyer_banner.png';
import { useState } from "react";
import { Link } from "react-scroll";

import { HiArrowNarrowRight } from "react-icons/hi";
// import {
// 	WiDayHaze,
// 	WiDaySunny,
// 	WiMoonAltWaxingCrescent6,
// } from "react-icons/wi";

const Home = () => {
		const [nav, setNav] = useState(false);
		const handleClick = () => setNav(!nav);
	// let greeting;
	// let icon = "";
	const date = new Date();
	const currentTime = date.toDateString();
	if (currentTime < 12) {
		// greeting = "Good morning";
		// icon = "morning";
	} else if (currentTime < 18) {
		// greeting = "Good Afternoon";
		// icon = "afternoon";
	} else {
		// greeting = "Good Evening";
		// icon = "evening";
	}
	const currentYear = new Date().getFullYear();
	return (
		<div name="home" className="w-full h-screen bg-[#0a192f]">
			{/* {Container} */}
			<div className="max-w-[1000px] mx-auto px-9  flex-col  justify-center h-full flex flex-col justify-center items-center w-full h-full">
			{/* <div className=" h-[40px] flex justify-center items-center px-4 text-gray-300"> */}
			{/* <p className="text-4xl sm:text-3xl font-bold text-[#a4b5ed]">
					{greeting}  <span>{icon === "morning" && <WiDayHaze />}
						{icon === "afternoon" && <WiDaySunny />}
						{icon === "evening" && <WiMoonAltWaxingCrescent6 />}</span>
						
				</p> */}
			{/* </div> */}
			<div className='hidden flex-1 lg:flex lg:flex-col items-end'>
        </div>
		<img  className="pt-3" style={{borderRadius:'50%', width:'60%'}}src={Image} alt='' />
				{/* <p className="text-blue-600">Hello, my name is </p> */}
				<h1 className="text-4xl sm:text-7x1 font-bold text-[#ccd6f6]">
					Recep Taylanhan
				</h1>
				<h2 className="text-4xl sm:text-6xl font-bold text-[#8892b0]">
					 Software Engineer
				</h2>
				<p className="font-bold text-[#ccd6f6] py-4 max-w-[800px]">
					My current position is UI Developer and specializing JavaScript and ReactJS.
					Currently, I am focusing on building responsive web applications using
					ReactJS.
				</p>
				<div className="pb-11">
				<Link onClick={handleClick} to="projects"
						smooth={true}
						duration={500} >
					<button  
					className="text-white group border-2 px-6 py-3 my-2 flex items-center  hover:bg-purple-600 hover:border-pink-600">
						View My Projects
						<span className="group-hover:rotate-90 duration-300">
							<HiArrowNarrowRight className="ml-3" />
						</span>
						
					</button>
					</Link>
				</div>
			</div>
			<footer className="fixed w-full h-[40px] flex justify-center items-center px-4 bg-[#17345f] text-gray-300">{`Copyright  ${currentYear} By Recep Taylanhan & All Rights Reserved`}</footer>
		</div>
	);
};
export default Home;
