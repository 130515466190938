import React from "react";
import todoApp from "../assets/todoApp.jpg";
import quizApp from "../assets/quizApp.gif";
import chatApp from "../assets/chat-app.png";
import restaurantApp from "../assets/restaurant-app.png";
import nextjsApp from "../assets/nextjsApp.png";
import movieApp from "../assets/movie-app.png";
const Projects = () => {
	return (
		<div
			name="projects"
			className=" w-full md:h-screen text-gray-300 bg-[#0a192f]"
		>
			<div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
				<div className="pb-8">
					<p className="text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600">
						Projects
					</p>
					<p className="py-6">My Basic Projects</p>
				</div>
				{/* {grid container line 15 ten baslar} */}
				<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
					{/* {Grid item}  19 dan line 39 a kadar */}
					<div
						style={{ backgroundImage: `url(${todoApp})` }}
						className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
					>
						{/* { hover Effects} */}

						<div className="opacity-0 group-hover:opacity-100">
							<div>
								<span className="text-2xl font-bold text-white tracking-wider">
									React ToDo App
								</span>
								<div className="pt-8 text-center">
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Demo
										</button>
									</a>
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Code
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div
						style={{ backgroundImage: `url(${quizApp})` }}
						className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
					>
						{/* { hover Effects} */}

						<div className="opacity-0 group-hover:opacity-100">
							<div>
								<span className="text-2xl font-bold text-white tracking-wider">
									React Quiz App
								</span>
								<div className="pt-8 text-center">
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Demo
										</button>
									</a>
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Code
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div
						style={{ backgroundImage: `url(${chatApp})` }}
						className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
					>
						{/* { hover Effects} */}

						<div className="opacity-0 group-hover:opacity-100">
							<div>
								<span className="text-2xl font-bold text-white tracking-wider">
									Real time Chat App
								</span>
								<div className="pt-8 text-center">
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Demo
										</button>
									</a>
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Code
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div
						style={{ backgroundImage: `url(${restaurantApp})` }}
						className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
					>
						{/* { hover Effects} */}

						<div className="opacity-0 group-hover:opacity-100">
							<div>
								<span className="text-2xl font-bold text-white tracking-wider">
									Restaurant Menu App
								</span>
								<div className="pt-8 text-center">
									<a href="https://main.d3onapi9w8uptp.amplifyapp.com">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Demo
										</button>
									</a>
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Code
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div
						style={{ backgroundImage: `url(${movieApp})` }}
						className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
					>
						{/* { hover Effects} */}

						<div className="opacity-0 group-hover:opacity-100">
							<div>
								<span className="text-2xl font-bold text-white tracking-wider">
									React  Quiz App
								</span>
								<div className="pt-8 text-center">
									<a href="https://main.d3onapi9w8uptp.amplifyapp.com">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Demo
										</button>
									</a>
									<a href="/">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Code
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div
						style={{ backgroundImage: `url(${nextjsApp})` }}
						className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
					>
						{/* { hover Effects} */}

						<div className="opacity-0 group-hover:opacity-100">
							<div>
								<span className="text-2xl font-bold text-white tracking-wider">
									Nextjs Meetup Application
								</span>
								<div className="pt-8 text-center">
									<a href="https://nextjs-meetup-application.vercel.app">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Demo
										</button>
									</a>
									<a href="https://nextjs-meetup-application.vercel.app">
										<button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
											Code
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Projects;
