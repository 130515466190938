import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import BackToTop from './components/BackToTop';

function App() {
	return (
		<div>
			<Header />
			<Home />
			<About />
			<Skills />
			<Projects />
			<Contact />
			<BackToTop />
		</div>
	);
}

export default App;
